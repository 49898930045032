<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Edit AP Other" :active="isActive" v-on:update:active="emitModalIsActive">

      <div :class="{'opacity-50 pointer-events-none': loading.initData}">
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Nomor *</label>
            <vs-input class="w-full" v-model="data.no_ap_other" disabled/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Proyek *</label>
            <v-select :options="proyeks" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_proyek"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Person Type *</label>
            <v-select :options="['REKANAN', 'STAF']" :clearable="false" v-model="data.person_type" @change="onChangePersonType($event)"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full" v-if="data.person_type === 'REKANAN'">
            <label class="ml-1 text-xs">Rekanan *</label>
            <div @click="modalRekanan.active = true">
              <vx-input-group>
                <vs-input :value="data.nama_rekanan" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search" :disabled="!data.id_proyek"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
          <div class="vx-col sm:w-3/12 w-full" v-if="data.person_type === 'STAF'">
            <label class="ml-1 text-xs">Staf *</label>
            <div @click="modalStaf.active = true">
              <vx-input-group>
                <vs-input :value="data.nama_rekanan" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search" :disabled="!data.id_proyek"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tanggal *</label>
            <flat-pickr class="w-full" v-model="data.tgl_ap_other"></flat-pickr>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tanggal Due *</label>
            <flat-pickr class="w-full" v-model="data.tgl_due_ap_other"></flat-pickr>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-input class="w-full" v-model="data.keterangan"/>
          </div>
        </div>

        <vs-divider> Detail Transaksi </vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="flex flex-wrap justify-between items-center mb-1">
              <div class="mb-4 md:mb-0 mr-4">
                <div class="flex">
                  <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2">Add</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="h-64 overflow-y-auto">
          <vs-table :data="data.details" stripe>
            <template slot="thead">
              <vs-th class="whitespace-no-wrap">Aksi</vs-th>
              <vs-th class="whitespace-no-wrap">Nomor</vs-th>
              <vs-th class="whitespace-no-wrap">COA Debet *</vs-th>
              <vs-th class="whitespace-no-wrap">COA Kredit *</vs-th>
              <vs-th class="whitespace-no-wrap">Files</vs-th>
              <vs-th class="whitespace-no-wrap">Total *</vs-th>
              <vs-th class="whitespace-no-wrap">Keterangan *</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
                <vs-td>
                  <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
                </vs-td>
                <vs-td><vs-input class="w-48" type="text" v-model="item.no_termin" disabled/></vs-td>
                <vs-td>
                  <div class="w-64">
                    <div @click="chooseCoa(item.uuid, 'DEBET')">
                      <vx-input-group>
                        <vs-input placeholder="COA Debet" :value="item.nama_coa_debet" readonly/>
                        <template slot="append">
                          <div class="append-text btn-addon">
                            <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                          </div>
                        </template>
                      </vx-input-group>
                    </div>
                  </div>
                </vs-td>
                <vs-td>
                  <div class="w-64">
                    <div @click="chooseCoa(item.uuid, 'KREDIT')">
                      <vx-input-group>
                        <vs-input placeholder="COA Kredit" :value="item.nama_coa_kredit" readonly/>
                        <template slot="append">
                          <div class="append-text btn-addon">
                            <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                          </div>
                        </template>
                      </vx-input-group>
                    </div>
                  </div>
                </vs-td>
                <vs-td>
                  <vs-input class="w-48" type="file" accept=".jpeg,.jpg,.png,.pdf" multiple v-model="item.filesTmp" @change="item.files = $event.target.files"/>
                </vs-td>
                <vs-td><v-money class="w-48 text-right" type="text" v-model="item.jml_total"/></vs-td>
                <vs-td><vs-input class="w-48" type="text" v-model="item.keterangan"/></vs-td>
              </vs-tr>
              <!--footer-->
              <vs-tr v-if="data.length > 0">
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td class="left"><v-money class="w-48 text-right" type="text" :value="grandTotal" disabled/></vs-td>
                <vs-td></vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>

        <div class="vx-row mt-6">
          <div class="vx-col w-full">
            <div class="flex justify-end w-full">
              <div class="flex">
                <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
                <vs-button :disabled="loading.saving" @click="save">
                  <span v-if="loading.saving" class="animate-pulse">Menyimpan...</span>
                  <span v-if="!loading.saving">Simpan</span>
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--modal rekanan-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Rekanan"
                :active="modalRekanan.active"
                v-on:update:active="modalRekanan.active = $event">
        <Rekanan v-if="data.person_type === 'REKANAN'"  :selectable="true" :externalFilter="filterRekanan" @selected="onSelectedModalRekanan"/>
      </vs-popup>

      <!--modal staf-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Staf"
                :active="modalStaf.active"
                v-on:update:active="modalStaf.active = $event">
        <Staf v-if="data.person_type === 'STAF'" :selectable="true" :externalFilter="filterStaf" @selected="onSelectedModalStaf"/>
      </vs-popup>

      <!--modal coa-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih COA"
                :active="modalCoa.active"
                v-on:update:active="modalCoa.active = $event">
        <Coa :selectable="true" :externalFilter="filterCoa" @selected="onSelectedModalCoa"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import ApOtherRepository from '@/repositories/accounting/ap-other-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import Rekanan from '@/views/pages/master/rekanan/Rekanan'
import Staf from '@/views/pages/master/staf/Staf'
import Coa from '@/views/pages/master/coa/Coa'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import VMoney from '@/views/components/v-money/VMoney'
import vSelect from 'vue-select'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'ApOtherEdit',
  props: ['isActive', 'idApOther'],
  components: {
    ValidationErrors,
    Rekanan,
    Staf,
    Coa,
    flatPickr,
    VMoney,
    'v-select': vSelect
  },
  mounted () {
    this.getAllProyek()
  },
  watch: {
    isActive (active) {
      if (active) {
        this.getInitData()
      }
    }
  },
  data () {
    return {
      loading: {
        initData: false,
        saving: false
      },
      errors: null,
      proyeks: [],
      modalRekanan: {
        active: false
      },
      modalStaf: {
        active: false
      },
      modalCoa: {
        rowUuid: null,
        active: false,
        type: null
      },
      data: {
        id_proyek: null,
        id_rekanan: null,
        id_staf: null,
        person_type: null,
        keterangan: null,
        details: [],
        deletedIds: []
      }
    }
  },
  computed: {
    grandTotal () {
      return _.sumBy(this.data.details, item => item.jml_total)
    },
    filterRekanan () {
      return { id_proyek: this.data.id_proyek }
    },
    filterStaf () {
      return { id_proyek: this.data.id_proyek }
    },
    filterCoa () {
      return {
        tipe: 'DETAIL',
        id_proyek: this.data.id_proyek
      }
    }
  },
  methods: {
    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getInitData () {
      this.loading.initData = true

      ApOtherRepository.show(this.idApOther)
        .then(response => {
          this.data = { ...this.data, ...response.data.data }
          this.data.person_type = this.data.id_rekanan ? 'REKANAN' : 'STAF'
          this.data.details = _.map(this.data.details, item => {
            item.uuid = uuid()
            return item
          })
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.loading.initData = false
        })
    },

    save () {
      this.errors = null
      this.loading.saving = true

      const params = convertToFormData(this.buildParams())
      ApOtherRepository.update(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.loading.saving = false
        })
    },

    buildParams () {
      return {
        id: this.data.id,
        id_proyek: this.data.id_proyek,
        id_rekanan: this.data.id_rekanan,
        id_staf: this.data.id_staf,
        tgl_ap_other: this.data.tgl_ap_other,
        tgl_due_ap_other: this.data.tgl_due_ap_other,
        keterangan: this.data.keterangan,
        details: _.map(this.data.details, item => _.pick(item, ['id', 'id_coa_debet', 'id_coa_kredit', 'jml_total', 'keterangan', 'files'])),
        deletedIds: this.data.deletedIds
      }
    },

    chooseCoa (rowUuid, type) {
      this.modalCoa.type = type
      this.modalCoa.rowUuid = rowUuid
      this.modalCoa.active = true
    },

    onSelectedModalRekanan (item) {
      this.data.id_rekanan = item.id
      this.data.nama_rekanan = item.nama
      this.modalRekanan.active = false
    },

    onSelectedModalStaf (item) {
      this.data.id_staf = item.id
      this.data.nama_rekanan = item.nama
      this.modalStaf.active = false
    },

    onSelectedModalCoa (data) {
      const uuid = this.modalCoa.rowUuid
      const index = _.findIndex(this.data.details, item => item.uuid === uuid)
      if (this.modalCoa.type === 'DEBET') {
        this.data.details[index].id_coa_debet = data.id
        this.data.details[index].nama_coa_debet = data.kode + ' - ' + data.nama
      } else {
        this.data.details[index].id_coa_kredit = data.id
        this.data.details[index].nama_coa_kredit = data.kode + ' - ' + data.nama
      }
      this.modalCoa.active = false
    },

    onChangePersonType (personType) {
      this.data.id_rekanan = null
      this.data.id_staf = null
      this.data.nama_rekanan = null
    },

    addRow () {
      const row = {
        uuid: uuid(),
        fileTmp: ''
      }
      this.data.details.push(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.data.details, item => item.uuid === uuid)
      const item = this.data.details[index]
      if (item.id) {
        this.data.deletedIds.push(item.id)
      }
      this.data.details.splice(index, 1)
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      if (!isActive) {
        this.resetData()
      }
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
